import './app.scss';

import React from 'react';
import { Switch, Route, matchPath } from 'react-router-dom';
import routes from './routes';
import { CookiesProvider } from 'react-cookie';
import MobilityAds from './components/MobilityAds';
import FacebookPixel from './components/FacebookPixel';
import AutoUncleMain from './components/AutoUncleMain';
import GoogleTagManager from './components/GoogleTagManager';

class App extends React.Component {
    render = () => (
        <CookiesProvider>
            <React.Fragment>
                <GoogleTagManager />
                <FacebookPixel />
                <AutoUncleMain />
                <Switch>
                    {
                        routes.map((route, idx) => <Route key={idx} {...route} />)
                    }
                </Switch>
                <MobilityAds />
            </React.Fragment>
        </CookiesProvider>
    )
}

export default App;