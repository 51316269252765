import React from 'react';
import CommonPage from '../../CommonPage';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../../components/Breadcrumb';
import KoenigImage from '../../../components/KoenigImage';
import Api from '../../../helper/api.helper'
import SimpleContent from '../../../components/SimpleContent';
import SimpleShop from '../../../components/SimpleShop';
import VoucherProductBox from './components/VoucherProductBox';

const CSS_CLASS = 'page-gutschein-shop'
const crumbs = [
    { label: 'Start', path: '/' },
    { label: 'Werkstatt', path: '/werkstatt/' },
    { label: 'Gutscheine', current_page: true }
];

class GutscheinShopPage extends React.Component {
    constructor(props) {
        super(props);

        let pageData, vouchers, postData;

        const hasStaticContext = typeof props.staticContext !== 'undefined';
        const hasContextPostData = hasStaticContext && typeof props.staticContext.postData !== 'undefined';
        const hasContextInitialData = hasStaticContext && typeof props.staticContext.initialData !== 'undefined';

        const isOnClient = typeof window !== 'undefined';
        const hasClientPostData = isOnClient && typeof window.__postData__ !== 'undefined';
        const hasClientIntialData = isOnClient && typeof window.__initialData__ !== 'undefined';

        if (hasStaticContext) {
            if (hasContextPostData) {
                postData = props.staticContext.postData;
            }
            if (hasContextInitialData) {
                [pageData, vouchers] = props.staticContext.initialData;
            }
        } else if (isOnClient) {
            if (hasClientPostData) {
                postData = window.__postData__;
            }
            if (hasClientIntialData) {
                [pageData, vouchers] = window.__initialData__;
            }
        }

        this.state = {
            ...pageData,
            vouchers,
            isContentVisible: true,

            ...postData,
        };
    }

    static requestInitialData(url) {
        if (url.indexOf('/werkstatt/gutscheine/') === 0) {
            return Promise.all(
                [
                    Api
                        .GET('/pages/gutschein_shop')
                        .catch(err => {
                            console.error(err);
                            return [];
                        }),
                    Api
                        .GET('/vouchers')
                        .catch(err => {
                            console.error(err);
                            return [];
                        })
                ]
            )

        }
    }

    handleSetContentVisible(isVisible) {
        this.setState({ isContentVisible: isVisible });
    }

    render() {
        const { headline = "", seo_content = {}, isContentVisible } = this.state;
        const classes = [CSS_CLASS + '--content'];
        isContentVisible ? '' : classes.push('content-visible');

        return (
            <CommonPage className={CSS_CLASS}>
                <Helmet>
                    <title>Autohaus König - Gutscheine</title>
                </Helmet>
                <Breadcrumb crumbs={crumbs} />
                <div className={classes.join(' ')}>

                    <h1 className={CSS_CLASS + '--headline'}>{headline}</h1>

                    {this.renderHeaderImg()}

                    <SimpleContent content={seo_content} />
                </div>

                {this.renderSimpleShop()}
            </CommonPage>
        );
    }

    renderHeaderImg() {
        const { teaser } = this.state;

        return (
            <div className={CSS_CLASS + '--header-img'}>
                {teaser && teaser.mobile
                    ? this.renderImage(teaser.mobile, CSS_CLASS + '--header-img--mobile')
                    : null}
                {teaser && teaser.desktop
                    ? this.renderImage(teaser.desktop, CSS_CLASS + '--header-img--desktop')
                    : null}
            </div>
        );
    }

    renderImage(src, cssClass) {
        const { headline } = this.state;

        return <KoenigImage className={cssClass} fileHash={src} alt={headline} />;
    }

    renderSimpleShop() {
        const { vouchers, shop_items = [] } = this.state;

        return (
            <SimpleShop
                type='voucher'
                products={vouchers}
                productComp={VoucherProductBox}
                itemLimit="20"
                shopUrl="/werkstatt/gutscheine/"
                {...this.state}
                setContentVisible={this.handleSetContentVisible.bind(this)} />
        );
    }
}

export default GutscheinShopPage
