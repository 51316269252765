export const uniqueItems = (brand, idx, brands) => {
    return brands.indexOf(brand) === idx;
}

export const normalizePossibleBrands = (retVal, brand) => {
    retVal.push(brand.replace('alfa-romeo', 'alfa_romeo'));
    if (brand === 'renault') {
        retVal.push('elektro');
    }
    if (['abarth', 'fiat'].indexOf(brand) !== -1) {
        retVal.push('fiat_abarth_prof');
    }
    return retVal;
};

export const sortBrands = (b1, b2) => {
    const nb1 = transformToPosNumber(b1);
    const nb2 = transformToPosNumber(b2);
    if (nb1 === nb2) {
        return 0;
    }
    return nb1 < nb2 ? -1 : 1;
};

export const transformToPosNumber = (brand) => {
    switch (brand) {
        case 'renault':
        case 'renault_plus':
            return 1;
        case 'dacia':
            return 2;
        case 'fiat':
        case 'fiat_abarth_prof':
            return 3;
        case "alfa_romeo":
            return 4;
        case 'jeep':
            return 5;
        case "volkswagen":
            return 6;
        case "opel":
            return 7;
        case 'kia':
            return 8;
        case "mazda":
            return 9;
        case 'suzuki':
            return 10;
        case 'mGMotor':
            return 11;
        case 'maxus':
            return 12;
        case 'alpine':
            return 13;
        case 'piaggio':
            return 14;
        case "e-schwalbe":
            return 15;
        case 'aprilia':
            return 16;
        case 'vespa':
            return 17;
        case 'ape':
            return 18;
        case "moto_guzzi":
            return 19;
        case 'elektro':
            return 99;
        default:
            return 0;
    }
};

export const reduceBrands = (retVal, brand) => {
    if (['sonstigeAutomarke', 'sonstigeRollermarke'].indexOf(brand) !== -1) {
        return retVal;
    }

    retVal.push(normalize(brand));

    if (brand === 'renault') {
        retVal.push('elektro')
    }
    return retVal;
}

export const normalize = brandId => {
    if (['abarth', 'fiat'].indexOf(brandId) !== -1) {
        return 'fiat_abarth_prof';
    }
    return brandId.replace('-', '_');
}